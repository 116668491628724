.container {
  padding: 0.5em;
}

.modal-container-lg {
  width: 1000px !important;
}

.container-fluid{
  background-color: white;
}