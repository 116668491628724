.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 20, 20, 0.15);
  border-radius: 0.25rem;
  color: #010b12;
  font-size: 16px;
  margin: 2px 0 0;
  width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #121213;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: rgb(20, 18, 18);
  background-color: #141617;
  border-color: #0e0e0e;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(24, 2, 2, 0.15);
  color: #131415;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.collapse {
  display: block !important;
}

.active {
  background-color: #6390ea !important;
  color: #1d0202 !important;
}

.fileExplorer {
  float: inline-start !important;
}

.draggable-bar {
  width: 10px;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 4px;
  margin: 4px;
}

.LightScrollbar {
  width: 10px;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 4px;
  margin: 4px;
}

.Toolbar {
  position: relative;
  display: flex;
  color: #d8e0f0;
  z-index: +1;
  padding-bottom: 4px;
}

.Toolbar i {
  margin-right: 5px;
  cursor: pointer;
}

.Toolbar i :hover {
  color: #d8e0f0;
}

.FloatLeft {
  padding-left: 4px;
  width: 100%;
  color: black;
  vertical-align: top;
}

.ToolbarFileFolder {
  position: absolute;
  text-align: right;
  width: 80%;
  color: transparent!important;
}

.ToolbarFileFolder :hover {
  color: #0c0c0c !important;
}

svg:not(:root) {
  vertical-align: top !important;
}

.cardContainer.size-xl {
  height: 250px;
  width: 250px;
}
