*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.inspector {
  margin-left: 220px;
}

.inspector pre {
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 15px;
}
