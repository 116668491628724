.linksGraph {
  border: 1px solid #c4cdd6;
  margin-top: 10px;
  background: #edf3f8;
}

.linksGraph > .nolinks {
  height: 265px;
  padding-top: 8em;
  margin: 0% 0% 0% 45%;
}

.tdValues {
  padding-left: 15px;
}