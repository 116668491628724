.tree {
  position: relative;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.m-node.placeholder {
  border: 1px dashed hsl(196, 62%, 93%);
}

.m-node .inner {
  color: #151516;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.m-node .node {
  display: inline-block;
  width: 100%;
  padding: 4px 5px;
}

.m-node .node.is-active {
  background-color: #050910;
}
