@media screen and (max-width: 768px) {
  .searchOptionsModal {
    top: 15em !important;
    left: 1em !important;
    width: 500px !important;
  }
}

.searchOptionsModal {
  top: 14em;
  left: -11em;
}

.searchOptionsModal > div > .modal-header {
  display: none;
}

.searchOptionsModal > div > .modal-body {
  overflow-y: unset;
}
